import React from 'react';
import ReactDOM from 'react-dom';

import "./css/monochrome.css";
import "./css/main.css";
import "./css/color.css";
import './index.css';


import App from './App';

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById('root')
);