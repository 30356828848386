import "./App.css";
import ContactForm from "./components/ContactForm";
import headerBg from "./assets/img/profile-img.png";
import img_project_1 from "./assets/img/ecommerce-img.png";
import img_project_2 from "./assets/img/star-wars-img.png";
import img_project_3 from "./assets/img/spotify-img.png";
import testimonialsBg from "./assets/img/img_bg_main.jpg";
import "react-toastify/dist/ReactToastify.css";

function App() {
	return (
		<div className="App">
			{/* <div
        style={{
          position: "fixed",
          bottom: 0,
          left: 0,
          backgroundColor: "white",
          textAlign: "center",
          width: "100%",
          zIndex: 100,
          boxShadow: "0 5px 8px 0",
        }}
      >
        <p
          style={{
            padding: "10px 20px 0 20px",
            color: "black",
            fontFamily: "Roboto Mono,monospace",
            fontSize: 14,
          }}
        >
          Hey congrats, you made it! Old colleague? Please send me a
          "testimonial" so I can feature it on the site. Thank you!
        </p>
      </div> */}

			{/*<!--Main menu-->*/}
			<div className="menu" style={{ minWidth: 480 }}>
				<div className="container">
					<div className="row">
						<div className="menu__wrapper d-lg-block col-md-12">
							<nav className="">
								<ul>
									<li>
										<a href="#hello">Hello</a>
									</li>
									<li>
										<a href="#resume">Resume</a>
									</li>
									<li>
										<a href="#portfolio">Portfolio</a>
									</li>
									<li>
										<a href="#contact">Contact</a>
									</li>
								</ul>
							</nav>
						</div>
					</div>
				</div>
			</div>

			{/*<!--Header-->*/}
			<header className="main-header" style={styles.headerStyles}>
				<div className="container">
					<div className="row personal-profile" style={{ width: "100%" }}>
						<div className="col-md-4 personal-profile__avatar">
							<img className="" src={headerBg} alt="avatar" />
						</div>
						<div className="col-md-8">
							<p className="personal-profile__name">Andrews Frempong_</p>
							<p className="personal-profile__work">frontend developer</p>
							<div className="personal-profile__contacts">
								<dl className="contact-list contact-list__opacity-titles">
									{/*<dt>Age:</dt>*/}
									{/*<dd>23</dd>*/}
									{/*<dt>Phone:</dt>*/}
									{/*<dd><a href="tel:82344563333">8 (234) 456-33-33</a></dd>*/}
									<dt>Email:</dt>
									<dd>
										<a href="mailto:frempongandrews@yahoo.com">
											frempongandrews@yahoo.com
										</a>
									</dd>
									<dt>Location:</dt>
									<dd>Manchester, UK</dd>
								</dl>
							</div>
							<p className="personal-profile__social">
								<a href="https://github.com/frempongandrews" target="_blank">
									<i className="fa fa-github"></i>
								</a>
								<a
									href="https://www.linkedin.com/in/andrews-frempong-14ab91105/"
									target="_blank"
								>
									<i className="fa fa-linkedin-square"></i>
								</a>
							</p>
						</div>
					</div>
				</div>
			</header>

			{/*<!--Hello-->*/}
			<section id="hello" className="container section">
				<div className="row">
					<div className="col-md-10">
						<h2 id="hello_header" className="section__title">
							Hi_
						</h2>
						<p className="section__description">
							I am frontend engineer able to build a web application from the
							ground up - from concept and backend to frontend. Skilled at
							writing well-designed and efficient code using current best
							practices in development. Fast learner and team player who is
							proficient in fullstack javascript development.
						</p>

						<p>
							Hobbies: when I'm not behind the keyboard, I'm either reading a
							book, learning a new move at a salsa class or playing football
							with friends.
						</p>
					</div>
				</div>
			</section>

			<hr />

			{/*<!--Resume-->*/}
			<section id="resume" className="container section">
				<div className="row">
					<div className="col-md-10">
						<h2 id="resume_header" className="section__title">
							Resume_
						</h2>
						<p className="section__description">
							I am extremely passionate about industry issues, tools, best
							practices and trends. I constantly strive to combine art of design
							and programming, comfortably bridging the gap between graphical
							design and technical implementation and defining not only how the
							application looks, but also how it works.
						</p>
					</div>
				</div>
				<div className="row" style={{ width: "100%" }}>
					<div className="col-md-8 section__resume resume-list">
						<h3 className="resume-list_title">education</h3>
						<div className="resume-list__block">
							<p className="resume-list__block-title">Masters Computing </p>
							<p className="resume-list__block-date">2017 - 2018</p>
							<p>Manchester Metropolitan University</p>
						</div>
						<div className="resume-list__block">
							<p className="resume-list__block-title">
								Advanced Software Engineering Program
							</p>
							<p className="resume-list__block-date">2016</p>
							<p>Northcoders - Manchester</p>
						</div>
						<div className="resume-list__block">
							<p className="resume-list__block-title">Economics</p>
							<p className="resume-list__block-date">2013 - 2016</p>
							<p>Manchester Metropolitan University</p>
						</div>
					</div>
				</div>
				<div className="row">
					<div className="col-md-8 section__resume resume-list">
						<h3 className="resume-list_title">employment</h3>
						{/* employment block */}
						<div className="resume-list__block">
							<p className="resume-list__block-title">Betfred - Sharp Gaming</p>
							<p className="resume-list__block-date">2022 - 2023</p>
							<p style={{ marginBottom: 20 }}>Frontend Developer</p>
							<p>
								Main responsibilities: Continuously enhance and add new
								functionalities to the Betfred betting platform, collaborate
								with the backend team to efficiently integrate with internal and
								3rd party APIs, implementation of new designs, maintenance of
								our internal design system, conduct code refactoring, document
								and review processes.
							</p>
							<p style={{ marginTop: 10 }}>
								Tech: {"  "} React, Typescript, Webpack, Redux, Git, Jira
							</p>
						</div>
						{/* employment block */}
						<div className="resume-list__block">
							<p className="resume-list__block-title">Pharmacy2u</p>
							<p className="resume-list__block-date">2022</p>
							<p style={{ marginBottom: 20 }}>Frontend Developer</p>
							<p>
								Main responsibilities: Build new features for the Pharmacy2u
								ecommerce platform, conduct code refactoring/review, document
								and review processes. .
							</p>
							<p style={{ marginTop: 10 }}>
								Tech: {"  "} Nextjs, React, Webpack, Git, Jira
							</p>
						</div>
						{/* employment block */}
						<div className="resume-list__block">
							<p className="resume-list__block-title">Wowcher</p>
							<p className="resume-list__block-date">2019 - 2022</p>
							<p style={{ marginBottom: 20 }}>Frontend Developer</p>
							<p>
								Main responsibilities: Maintain and build new features for the
								wowcher ecommerce platform, implement new features in
								collaboration with the backend team, implement new designs, code
								refactoring/review, help transition from Angularjs to React.
							</p>
							<p style={{ marginTop: 10 }}>
								Tech: {"  "} Angularjs, React, Webpack, Git, Jira
							</p>
						</div>
						{/* employment block */}
						<div className="resume-list__block">
							<p className="resume-list__block-title">Padoq</p>
							<p className="resume-list__block-date">2018 - 2019</p>
							<p style={{ marginBottom: 20 }}>Frontend Developer</p>
							<p>
								Main responsibilities: Build a CMS for internal staff to manage
								the Padoq social media platform; this included taking Photoshop
								designs to pixel perfect frontend code, collaboration with
								backend developer for correct interaction with the RESTful API,
								implementation of authentication and authorization on the
								frontend using Redux, code refactoring/review.
							</p>
							<p style={{ marginTop: 10 }}>
								Tech: {"  "} React, Redux, Webpack, Git, Jira
							</p>
						</div>
						{/* employment block */}
						<div className="resume-list__block">
							<p className="resume-list__block-title">
								Freelance web developer
							</p>
							<p className="resume-list__block-date">2017</p>
							<p style={{ marginBottom: 20 }}>Frontend Developer</p>
							<p>
								Converting Photoshop designs to static websites for clients.
							</p>
							<p style={{ marginTop: 10 }}>
								Tech: {"  "} HTML, CSS, Javascript, Git
							</p>
						</div>
					</div>
				</div>
				<div
					className="row section__resume progress-list js-progress-list"
					style={{ width: "100%" }}
				>
					<div className="col-md-12">
						<h3 className="progress-list__title">skills</h3>
					</div>
					<div className="col-md-5 mr-auto">
						{/* skill block */}
						<div className="progress-list__skill">
							<p>
								<span className="progress-list__skill-title">html5 / css3</span>
								<span className="progress-list__skill-value">90%</span>
							</p>
							<div className="progress">
								<div
									className="progress-bar"
									role="progressbar"
									aria-valuenow="90"
									aria-valuemin="0"
									aria-valuemax="100"
									style={{ width: "90%" }}
								></div>
							</div>
						</div>

						{/* skill block */}
						<div className="progress-list__skill">
							<p>
								<span className="progress-list__skill-title">
									javascript / Typescript
								</span>
								<span className="progress-list__skill-value">80%</span>
							</p>
							<div className="progress">
								<div
									className="progress-bar"
									role="progressbar"
									aria-valuenow="80"
									aria-valuemin="0"
									aria-valuemax="100"
									style={{ width: "80%" }}
								></div>
							</div>
						</div>

						{/* skill block */}
						<div className="progress-list__skill">
							<p>
								<span className="progress-list__skill-title">nodejs</span>
								<span className="progress-list__skill-value">80%</span>
							</p>
							<div className="progress">
								<div
									className="progress-bar"
									role="progressbar"
									aria-valuenow="80"
									aria-valuemin="0"
									aria-valuemax="100"
									style={{ width: "80%" }}
								></div>
							</div>
						</div>
					</div>
					<div className="col-md-5 mr-auto">
						{/* skill block */}
						<div className="progress-list__skill">
							<p>
								<span className="progress-list__skill-title">
									styled components / bootstrap / Tailwindcss
								</span>
								<span className="progress-list__skill-value">80%</span>
							</p>
							<div className="progress">
								<div
									className="progress-bar"
									role="progressbar"
									aria-valuenow="80"
									aria-valuemin="0"
									aria-valuemax="100"
									style={{ width: "80%" }}
								></div>
							</div>
						</div>

						{/*<div className="progress-list__skill">*/}
						{/*<p>*/}
						{/*<span className="progress-list__skill-title">grunt</span>*/}
						{/*<span className="progress-list__skill-value">60%</span>*/}
						{/*</p>*/}
						{/*<div className="progress">*/}
						{/*<div className="progress-bar" role="progressbar" aria-valuenow="60" aria-valuemin="0" aria-valuemax="100">*/}
						{/*</div>*/}
						{/*</div>*/}
						{/*</div>*/}

						{/* skill block */}
						<div className="progress-list__skill">
							<p>
								<span className="progress-list__skill-title">
									git / bitbucket / jira
								</span>
								<span className="progress-list__skill-value">90%</span>
							</p>
							<div className="progress">
								<div
									className="progress-bar"
									role="progressbar"
									aria-valuenow="90"
									aria-valuemin="0"
									aria-valuemax="100"
									style={{ width: "90%" }}
								></div>
							</div>
						</div>

						{/* skill block */}
						<div className="progress-list__skill">
							<p>
								<span className="progress-list__skill-title">
									react/ Nextjs / redux
								</span>
								<span className="progress-list__skill-value">85%</span>
							</p>
							<div className="progress">
								<div
									className="progress-bar"
									role="progressbar"
									aria-valuenow="85"
									aria-valuemin="0"
									aria-valuemax="100"
									style={{ width: "85%" }}
								></div>
							</div>
						</div>
					</div>
				</div>
			</section>

			{/*<!--Portfolio-->*/}
			<section id="portfolio" className="container section">
				<div className="row">
					<div className="col-md-12">
						<h2 id="portfolio_header" className="section__title">
							Projects_
						</h2>
					</div>
				</div>

				<div className="portfolio-cards">
					{/* <div
            className="row project-card"
            data-toggle="modal"
            data-target="#portfolioModal"
            data-portfolio-tag="web-sites"
            style={{ cursor: "default" }}
          >
            <div
              className="col-md-12 col-lg-5 project-card__img"
              style={{ border: "1px solid #f1f1f1" }}
            >
              <img
                className=""
                src={img_project_1}
                alt="project-img"
                style={{ padding: "20px" }}
              />
            </div>
            <div className="col-md-12 col-lg-7 project-card__info">
              <h3 className="project-card__title">
                Ecommerce Website with stripe checkout{" "}
              </h3>

              <p style={{ marginBottom: 30 }}>
                Features include: user signup, email verification after user
                signup, user login, stripe checkout. State management with
                context API{" "}
              </p>
              <p className="project-card__stack">Fullstack</p>
              <p className="project-card__stack">Used stack:</p>
              <ul className="tags">
                <li>React</li>
                <li>Context api</li>
                <li>Nextjs</li>
                <li>Nodejs</li>
                <li>MongoDB</li>
              </ul>

              <a
                href="https://simple-ecommerce-frontend.vercel.app"
                target="_blank"
                className="project-card__link"
              >
                ecommerce website
              </a>
          
            </div>
          </div> */}

					{/* <div
            className="row project-card"
            data-toggle="modal"
            data-target="#portfolioModal"
            data-portfolio-tag="mobile apps"
            style={{ cursor: "default" }}
          >
            <div
              className="col-md-12 col-lg-5 project-card__img"
              style={{ border: "1px solid #f1f1f1" }}
            >
              <img
                className=""
                src={img_project_2}
                alt="project-img"
                style={{ padding: "20px" }}
              />
            </div>
            <div className="col-md-12 col-lg-7 project-card__info">
              <h3 className="project-card__title">Star Wars Webapp</h3>
             
              <p style={{ marginBottom: 30 }}>
                The most complete star wars webapp on the internet. Uses the{" "}
                <a href="https://swapi.dev" target="_blank">
                  star wars API
                </a>
              </p>
              <p className="project-card__stack">Frontend</p>
              <p className="project-card__stack">Used stack:</p>
              <ul className="tags">
                <li>React</li>
                <li>Nextjs</li>
                <li>Context API</li>
              </ul>
              <a
                href="https://next-star-wars.vercel.app"
                target="_blank"
                className="project-card__link"
              >
                star wars app
              </a>
            </div>
          </div>  */}

					<div
						className="row project-card"
						data-toggle="modal"
						data-target="#portfolioModal"
						data-portfolio-tag="landing-pages"
						style={{ cursor: "default" }}
					>
						<div
							className="col-md-12 col-lg-5 project-card__img"
							style={{ border: "1px solid #f1f1f1" }}
						>
							<img
								className=""
								src={img_project_3}
								alt="project-img"
								style={{ padding: "20px" }}
							/>
						</div>
						<div className="col-md-12 col-lg-7 project-card__info">
							<h3 className="project-card__title">Spotify clone - Frontend</h3>

							<p style={{ marginBottom: 30 }}>
								A clone of the spotify frontend webapp
							</p>
							<p className="project-card__stack">Frontend</p>
							<p className="project-card__stack">Used stack:</p>
							<ul className="tags">
								<li>React</li>
								<li>Nextjs</li>
								<li>Tailwindcss</li>
							</ul>
							<a
								href="https://spotify-clone-tailwindcss.vercel.app"
								target="_blank"
								className="project-card__link"
							>
								spotify clone
							</a>
						</div>
					</div>
					<p>More Awesome Projects Coming Soon...</p>
				</div>
			</section>
			<ContactForm />
		</div>
	);
}

const styles = {
	headerStyles: {
		backgroundImage: "url(" + headerBg + ")",
		backgroundPosition: "center",
		backgroundSize: "cover",
		backgroundRepeat: "no-repeat",
	},
	testimonialsStyles: {
		backgroundImage: "url(" + testimonialsBg + ")",
		backgroundPosition: "center",
		backgroundSize: "cover",
		backgroundRepeat: "no-repeat",
	},
};

export default App;
