import React from "react";
import { useEffect, useState } from "react";
import { useForm, ValidationError } from "@formspree/react";
import testimonialsBg from "../assets/img/img_bg_main.jpg";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const toastOptions = {
  position: "bottom-right",
  autoClose: 5000,
  hideProgressBar: false,
  closeOnClick: true,
  pauseOnHover: true,
  draggable: true,
  progress: undefined,
};

function ContactForm() {
  const currentDate = new Date();
  const currentYear = currentDate.getFullYear();

  const [formData, setFormData] = useState({
    name: "",
    email: "",
    message: "",
  });

  const [errors, setErrors] = useState({
    name: "",
    email: "",
    message: "",
  });

  useEffect(() => {
    
  });

  const setInputValue = (e) => {
    
    setFormData((prevState) => {
      return {
        ...prevState,
        [e.target.name]: e.target.value,
      };
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const errors = {};
    if (formData.name.trim().length < 3) {
      errors.name = "Name should be at least 3 characters";
    }

    if (formData.email.trim().length < 3) {
      errors.email = "Email should be at least 3 characters";
    }

    if (formData.message.trim().length < 10) {
      errors.message = "Message should be at least 10 characters";
    }

    if (Object.keys(errors).length > 0) {
      setErrors((prevState) => {
        return {
          ...errors,
        };
      });
      return;
    }

    try {
      // formspree account - coneglianopower
      const res = await fetch("https://formspree.io/f/mzbopblj", {
        method: "POST",
        // body: formData,
        body: new FormData(e.target),
        headers: {
          Accept: "application/json",
        },
      });

      if (!res.ok) {
        toast.error("Error while submitting form", toastOptions);
        return;
      }

      toast.success(
        "Thanks for your email. I'll get back to you in 24 hours.",
        toastOptions
      );
      setFormData({
        name: "",
        email: "",
        message: "",
      });
      setErrors({
        name: "",
        email: "",
        message: "",
      });
    } catch (e) {
      toast.error("Error while submitting form", toastOptions);
    }
  };

  return (
    <div>
      <ToastContainer />
      {/*<p>{successMsg}</p>*/}

      <div className="background" style={styles.testimonialsStyles}>
        <div id="contact" className="container section">
          <div className="row">
            <div className="col-md-12">
              <p id="contacts_header" className="section__title">
                Get in touch_
              </p>
            </div>
          </div>
          <div className="row contacts" style={{width: "100%"}}>
            {/*<div className="col-md-5 col-lg-4">*/}
            {/*    <div className="contacts__list">*/}
            {/*        <dl className="contact-list">*/}
            {/*            /!*<dt>Phone:</dt>*!/*/}
            {/*            /!*<dd><a href="tel:82344563333">8 (234) 456-33-33</a></dd>*!/*/}
            {/*            /!*<dt>Skype:</dt>*!/*/}
            {/*            /!*<dd><a href="skype:iamivanovivan">iamivanovivan</a></dd>*!/*/}
            {/*            /!*<dt>Email:</dt>*!/*/}
            {/*            /!*<dd><a href="mailto:frempongandrews@yahoo.com">frempongandrews@yahoo.com</a></dd>*!/*/}
            {/*        </dl>*/}
            {/*    </div>*/}
            {/*    <div className="contacts__social">*/}
            {/*        <ul>*/}
            {/*            /!*<li><a href="">Facebook</a></li>*!/*/}
            {/*            /!*<li><a href="">Linkedin</a></li>*!/*/}
            {/*            /!*<li><a href="">GitHub</a></li>*!/*/}
            {/*            /!*<li><a href="">Bitbucket</a></li>*!/*/}
            {/*        </ul>*/}
            {/*    </div>*/}
            {/*</div>*/}
            <div className="col-md-12 col-lg-12">
              <div className="contacts__form">
                {/*<p className="contacts__form-title">Or just write me a letter here_</p>*/}
                <form className="js-form" onSubmit={handleSubmit}>
                  <div className="form-group">
                    <input
                      className="form-field js-field-name"
                      placeholder="Your name"
                      required
                      id="email"
                      type="text"
                      name="name"
                      value={formData.name}
                      onChange={setInputValue}
                    />
                    {errors.name && <p className="error">{errors.name}</p>}
                    <span className="form-validation"></span>
                    <span className="form-invalid-icon">
                      <i className="mdi mdi-close" aria-hidden="true"></i>
                    </span>
                  </div>
                  <div className="form-group">
                    <input
                      className="form-field js-field-email"
                      placeholder="Your e-mail"
                      required
                      id="email"
                      type="email"
                      name="email"
                      value={formData.email}
                      onChange={setInputValue}
                    />
                    {errors.email && <p className="error">{errors.email}</p>}
                    <span className="form-validation"></span>
                    <span className="form-invalid-icon">
                      <i className="mdi mdi-close" aria-hidden="true"></i>
                    </span>
                  </div>
                  <div className="form-group">
                    <textarea
                      className="form-field js-field-message"
                      placeholder="Type the message here"
                      required
                      id="message"
                      name="message"
                      value={formData.message}
                      onChange={setInputValue}
                    />
                    {errors.message && (
                      <p className="error">{errors.message}</p>
                    )}
                    <span className="form-validation"></span>
                    <span className="form-invalid-icon">
                      <i className="mdi mdi-close" aria-hidden="true"></i>
                    </span>
                  </div>
                  <button
                    className="site-btn site-btn--form"
                    type="submit"
                    value="Send"
                    style={{ cursor: "pointer" }}
                  >
                    Send
                  </button>
                </form>
              </div>
              <div className="footer">
                <p>© {currentYear} Andrews Frempong. All Rights Reserved</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

const styles = {
  testimonialsStyles: {
    backgroundImage: "url(" + testimonialsBg + ")",
    backgroundPosition: "center",
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
  },
};

export default ContactForm;
